const styles = {
    formContainer: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    paperContainer: {
        padding: '20px 100px',
        maxWidth: '1200px',
    },
    headerContainer: {
        fontSize: '2rem',
        fontWeight: 'bold',
        marginTop: '30px',
        borderBottom: '1px solid rgba(0,0,0,0.25)',
        color: 'rgba(16, 64, 107, 0.25)'
    },
    submitButton: {
        width: '100px',
        height: '40px',
        borderRadius: '5px',
        fontSize: '1.25rem',
        marginLeft: '25px'
    },
    submitColor: {
        backgroundColor: '#10406B',
        color: 'white',
    },
    hover: {
        cursor: 'pointer',
        backgroundColor: 'rgb(66,150,180)',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    buttonGroup: {
        margin: '20px',
        width: '100%',
        padding: '20px 100px',
        display: 'inline-flex',
        justifyContent: 'flex-end',
    },
    modalForm: {
        margin: '0 2vw',
        paddingTop: '5vh'
    },
    buttonInputContainer: {
        marginTop: '20px',
        width: '100%',
        border: '1px solid rgba(0,0,0,0.25)',
        borderRadius: '25px',
        padding: '30px 50px 50px 50px'
    },
    buttonInputAddRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonInputRow: {
        margin: '0 20px',
        width: '95%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonInputRowNumber: {
        color: 'rgba(0,0,0,0.54)',
        paddingRight: '10px',
        paddingTop: '10px'
    },
    buttonInputLabel: {
        color: 'rgba(0,0,0,0.54)',
	    fontSize: '14px',
    },
    addInputIcon: {
        fontSize: '1.25rem',
        marginRight: '15px',
        color: 'rgba(67, 150, 180, 0.75)',
    },
    litteIcon: {
        fontSize: '1.25rem',
        marginRight: '15px',
    },
    iconHover: {
        cursor: 'pointer',
        color: 'rgb(36,78,118)',
        fontSize: '1.5rem',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    toggle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '25px'
    },
    toggleRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        marginTop: '25px'
    },
    loading: {
        minWidth: '100%',
        height: '100%',
        minHeight: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectorRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '10px'
    },
    selectorContainer: {
        width: '100%',
        maxWidth: '350px'
    },
    offerSelectionRow: {
        width: '100%',
        display: 'inline-flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: '40px 0'
    },
    toggleGroup: {
        width: '300px',
        margin: '0 100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    }
};

export default styles;