import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import styles from './ArticleDialog.css';
import TextField from '@material-ui/core/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogBase from '../DialogBase';
import { useMutation } from '@apollo/client';
import { SAVE_ARTICLE } from '../../../graphql/mutations';
import { ARTICLES } from '../../../graphql/queries';
import InputLabel from '@material-ui/core/InputLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OfferSelector from './OfferSelector';
import BlockSelector from './BlockSelector';

const LittleIcon = ({icon, color, handleClick}) => {
    const [ isHovering, setHovering ] = useState(false);
    
    const iconStyle = Object.assign({},
        styles.litteIcon,
        {color: color},
        isHovering && styles.iconHover
    );

    return(
        <FontAwesomeIcon
            icon={['fal', icon]}
            size='1x'
            style={iconStyle}
            onClick={handleClick}
            onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
        />
    );
};

const ArticleDialog = () => {
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { article } = selectionState;
    const { addToast } = useToasts();
    const blankButton = { button: '' };
    const [ buttonState, setButtonState ] = useState([{button: ''}, {button: ''}, {button: ''}]);
    const [ cciActive, setCciActive ] = useState(!!article?.cci_link);
    const [ rsocActive, setRsocActive ] = useState(!!article?.rsoc_active);
    const [ rsocDesktop, setRsocDesktop ] = useState(!!article?.rsoc_desktop);
    const [ blockActive, setBlockActive ] = useState(!!article?.offer_block);
    const [ offerBlock, setOfferBlock ] = useState(article?.offer_block ?? '');
    const [ topOfferActive, setTopOfferActive ] = useState(!!article?.offer1);
    const [ topOffer, setTopOffer ] = useState(article?.offer1 ?? '');
    const [ bottomOfferActive, setBottomOfferActive ] = useState(!!article?.offer2);
    const [ bottomOffer, setBottomOffer ] = useState(article?.offer2 ?? '');
    const [ isSaving, setSaving ] = useState(false);
    const { register, errors, handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });

    const close = () => {
        dispatchSelection({ type: 'CLEAR_DATA'  });
        dispatchSelection({ type: 'TOGGLE_ARTICLE_DIALOG' });
    };

    const handleRedirect = (data) => {
        if (data.saveArticle.success) {
            addToast('Successfully SAVED Article!', { appearance: 'success', autoDismiss: true });
            setSaving(false);
        } else {
            console.error(data.message);
            addToast('Failed!', { appearance: 'error', autoDismiss: true });
            setSaving(false);
        };
        close();
    };

    const [saveArticle] = useMutation(
        SAVE_ARTICLE, {
        refetchQueries: [{ query: ARTICLES }],
        onCompleted: handleRedirect
    });

    const handleAddInputClick = () => {
        if(buttonState.length > 6) return;
        setButtonState([...buttonState, { ...blankButton }]);
    };

    const handleRemoveInputClick = (idx) => {
        if(buttonState.length < 4) return;
        let newState = buttonState.filter((el, i) => i !== idx);
        setButtonState(newState);
    };

    const handleButtonChange = (e) => {
        const updatedButtons = [...buttonState];
        updatedButtons[Number(e.target.id)]['button'] = e.target.value;
        setButtonState(updatedButtons);
    };

    const handleCciToggle = (e) => {
        setCciActive(e.target.checked)
    };
    const handleRsocToggle = (e) => {
        setRsocActive(e.target.checked)
    };
    const handleDesktopToggle = (e) => {
        setRsocDesktop(e.target.checked)
    };

    const handleSubmission = (formData) => {
        setSaving(true);
        const { key, title, header, sub_text, button_title, cci_link } = formData;
        const buttonValues = buttonState.map((input) => (
            input.button
        ));
        const newButtonGroupKey = `${key.toLowerCase()}-button_group-${new Date().getTime()}`;
        const cleanButtons = article?.button_groups?.map((obj) => ({ group_key: obj.group_key, buttons: obj.buttons })) || null;
        const sendData = {
            key: key.toLowerCase(),
            title,
            header: header || '',
            sub_text: sub_text || '',
            button_title: button_title || '',
            offer1: topOfferActive ? topOffer : null,
            offer2: bottomOfferActive ? bottomOffer : null,
            offer_block: blockActive ? offerBlock : null,
            cci_link: cciActive ? cci_link : '',
            rsoc_active: rsocActive,
            rsoc_desktop: rsocDesktop,
            active_button_group_key: !!article ? article.active_button_group_key : newButtonGroupKey,
	        button_groups: !!article ? cleanButtons : [
                {
                    group_key: newButtonGroupKey ,
                    buttons: buttonValues
                }
            ]
        };
        // console.log('Article:', sendData);
        saveArticle({ variables: { article: sendData } });
    };

    return (
        <DialogBase
            open={selectionState.showArticleDialog}
            close={close}
            title={!!article ? 'Edit Article:' : 'Add New Article:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={null}
        >
            <TextField
                name='key'
                className='form__text-input'
                label='Article Key - (Must be unique)'
                fullWidth
                autoFocus={!article}
                error={!!errors.key}
                margin='normal'
                defaultValue={article ? article.key : ''}
                inputRef={ register({ required: 'an Article Key is required!' }) }
            />
            <TextField
                name='title'
                autoFocus={!!article}
                className='form__text-input'
                label='Display Title - (For easy reference)'
                fullWidth
                error={!!errors.title}
                margin='normal'
                defaultValue={article ? article.title : ''}
                inputRef={ register({ required: 'an Article Title is required!' }) }
            />
            <div style={styles.toggle}>
                <FormControlLabel 
                    control={<Switch 
                        checked={blockActive}
                        onChange={(e) => setBlockActive(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} 
                    label="Offer Block?" 
                />
            </div>
            {blockActive && (
                <BlockSelector
                    value={offerBlock}
                    setValue={(e) => setOfferBlock(e.target.value)}
                />
            )}
            <div style={styles.offerSelectionRow}>
                <div style={styles.toggleGroup}>
                    <div style={styles.toggle}>
                        <FormControlLabel 
                            control={<Switch 
                                checked={topOfferActive}
                                onChange={(e) => setTopOfferActive(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />} 
                            label="Top Offer?" 
                        />
                    </div>
                    {topOfferActive && (
                        <OfferSelector 
                            id='top-offer-selector'
                            label='Top Offer'
                            value={topOffer}
                            setValue={(e) => setTopOffer(e.target.value)}
                        />
                    )}
                </div>
                <div style={styles.toggleGroup}>
                    <div style={styles.toggle}>
                        <FormControlLabel 
                            control={<Switch 
                                checked={bottomOfferActive}
                                onChange={(e) => setBottomOfferActive(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />} 
                            label="Bottom Offer?" 
                        />
                    </div>
                    {bottomOfferActive && (
                        <OfferSelector 
                            id='bottom-offer-selector'
                            label='Bottom Offer'
                            value={bottomOffer}
                            setValue={(e) => setBottomOffer(e.target.value)}
                        />
                    )}
                </div>
            </div>
            <div style={styles.toggleRow}>
                <FormControlLabel 
                    control={<Switch 
                        checked={rsocActive}
                        onChange={handleRsocToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} 
                    label="RSOC Active?" 
                />
                {rsocActive && (
                    <FormControlLabel 
                        control={<Switch
                            checked={rsocDesktop}
                            onChange={handleDesktopToggle}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} 
                        label="Always show RSOC for Desktop traffic?"
                    />
                )}
            </div>
            {rsocActive && (
            <>
                <TextField
                    name='header'
                    className='form__text-input'
                    label='Header - (Main title for article)'
                    fullWidth
                    error={!!errors.header}
                    margin='normal'
                    defaultValue={article ? article.header : ''}
                    inputRef={ register({ required: 'an Article Header is required!' }) }
                />
                <TextField
                    name='sub_text'
                    className='form__text-input'
                    label='Sub-Text - (Descriptive paragraph)'
                    fullWidth
                    error={!!errors.sub_text}
                    margin='normal'
                    multiline
                    defaultValue={article ? article.sub_text : ''}
                    inputRef={ register({ required: 'Article SubText is required!' }) }
                />
                <TextField
                    name='button_title'
                    className='form__text-input'
                    label='Button Header - (Search and Compare ...)'
                    fullWidth
                    error={!!errors.button_title}
                    margin='normal'
                    defaultValue={article ? article.button_title : ''}
                    inputRef={ register({ required: 'a Button Group Title is required!' }) }
                />
            </>
            )}
            <div style={styles.toggle}>
                <FormControlLabel 
                    control={<Switch 
                        checked={cciActive}
                        onChange={handleCciToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} 
                    label="Click Crafted Insight?" 
                />
            </div>
            {cciActive && 
                <TextField
                    name='cci_link'
                    className='form__text-input'
                    label='CCI Article Link URL'
                    fullWidth
                    margin='normal'
                    defaultValue={!!article ? article.cci_link : ''}
                    inputRef={ register() }
                />
            }
            {/* If this is a new article, set the first button group */}
            {!article && (
                <div style={styles.buttonInputContainer}>
                    <div style={styles.buttonInputAddRow}>
                        <InputLabel id='pid-input-label' style={styles.buttonInputLabel}>Buttons</InputLabel>
                        <LittleIcon 
                            icon='plus-circle' 
                            color='rgba(67, 150, 180, 0.75)' 
                            handleClick={handleAddInputClick}
                        />
                    </div>
                    { buttonState.map((val, idx) => {
                        const inputId = `button-${idx}`;
                        return (
                            <div key={`button-input__${idx}`} style={styles.buttonInputRow}>
                                <div style={styles.buttonInputRowNumber}>{`${idx + 1}.)`}</div>
                                <TextField
                                    id={`${idx}`}
                                    name={inputId}
                                    className='button'
                                    fullWidth
                                    margin='normal'
                                    value={buttonState[idx].button}
                                    onChange={handleButtonChange}
                                />
                                {idx !== 0 && (
                                    <LittleIcon 
                                        icon='minus-circle' 
                                        color='red' 
                                        handleClick={() => handleRemoveInputClick(idx)}
                                    />
                                )}
                            </div>
                        );
                    })
                    }
                </div>
            )}
        </DialogBase>
    );
};

export default ArticleDialog;